<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              variant="info"
              size="sm"
              title="Add New" @click="isAddNewUserSidebarActive=true"> <feather-icon icon="PlusIcon" /> Add New User
              </b-button>
          </b-col>
          <vue-table-dynamic
      ref="refInvoicesListTable"
      :params="fetchInvoices">
           <template v-slot:column-0="{ props }">
           <span>{{ props.row }}</span>
           </template>
           <template v-slot:column-8="{ props }">
        <b-button-group >
            <b-button
              variant="info"
              size="sm"
              title="Edit" @click="editUser( JSON.stringify(props.cellData) )"> <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
              variant="danger"
              size="sm"
              style="margin-left:10px;"
              @click="deleteUser(props.cellData.UserID)"
              title="Delete"> <feather-icon icon="TrashIcon" />
              </b-button>
    </b-button-group>
      </template>
      </vue-table-dynamic>
          <!-- Search -->
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BButtonGroup,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from 'axios'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { getToken } from '@/auth/utils'
import { ref } from '@vue/composition-api'
import useInvoicesList from './useInvoicesList'
import UserListAddNew from './UserListAddNew.vue'

const userToken = getToken()
const headers = {
  'Content-Type': 'application/json',
  'X-Token-Access': `Bearer ${userToken}`,
}
const fetchInvoicesData = [['No', 'Name', 'Email', 'Phone', 'Gender', 'Role', 'Unit Kerja', 'City', 'Actions']]
const InvoiceId = ''
const modalUploadShow = false
const uploadInvoiceId = ''
const InvoiceDate = ''
const InvoiceNumber = ''
const modalShow = false
const fileAttachments = []
const fetchInvoicesUnsliced = []
const startDateRaw = new Date()
const endDateRaw = new Date()
startDateRaw.setDate(startDateRaw.getDate() - 30)
export default {
  components: {
    BButton,
    BDropdownItem,
    BDropdown,
    BBadge,
    BButtonGroup,
    BAvatar,
    BMedia,
    BFormInput,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    DateRangePicker,
    BTable,
    BPagination,
    vSelect,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)
    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      branchId,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoicesListTable,
      refetchData,
      startDate,
      endDate,
      // UI
      resolveInvoicesRoleVariant,
      resolveInvoicesRoleIcon,
      resolveInvoicesStatusVariant,

    } = useInvoicesList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoicesListTable,
      refetchData,
      startDate,
      endDate,
      // UI
      resolveInvoicesRoleVariant,
      resolveInvoicesRoleIcon,
      resolveInvoicesStatusVariant,
      statusOptions,
      InvoiceId,
      InvoiceDate,
      InvoiceNumber,
      branchId,
    }
  },
    watch: {
        branchId: 'getAllInvoices',
    },
  data() {
  const invoicesAllFields = {
      Customer: 'Customer',
      'Job No': 'JobNo',
      'Inv Number': 'InvoiceNumber',
      'Inv   Date': 'InvoiceDate',
      Ref: 'Reference',
      Currency: 'Currency',
      DPP: 'DPP',
      Tax: 'Tax',
      'Grand Total': 'GrandTotal',
      'Input Pusat Date': 'Pusat_Timestamp',
      'InV Date pusat': 'Pusat_InvoiceDate',
      'No Inv Pusat': 'Pusat_NoInvoice',
      Confirmed: {
        field: 'invoiceApproved',
        callback: value => (value === 1 ? 'Yes' : 'No'),
      },
      'Supporting Doc': {
        field: 'FlagInvoiceAttachment',
        callback: value => (parseInt(value, 8) > 0 ? 'Yes' : 'No'),
      },
      'Void Date': 'VoidDate',
    }
      return {
        fetchInvoices: {
          data: fetchInvoicesData,
          header: 'row',
          enableSearch: true,
          border: true,
          stripe: true,
          pagination: true,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40, 50],
          showTotal: true,
          columnWidth: [{ column: 0, width: 50 } , { column: 1, width: 100 }, { column: 2, width: 200 }, { column: 3, width: 120 }, { column: 4, width: 80 }, { column: 5, width: 120 }, { column: 6, width: 120 }, { column: 7, width: 100 }, { column: 8, width: 120 }],
        },
        branches: [],
        fetchInvoicesUnsliced,
        invoicesAllFields,
        modalUploadShow,
        uploadInvoiceId,
        file: '',
        fileAttachments,
        modalShow,
        config: {
              wrap: true,
              dateFormat: 'Y-m-d',
          },
        pickerDates: {
            startDate: this.dataMeta.startDate ? this.dataMeta.startDate : startDateRaw,
            endDate: this.dataMeta.endDate ? this.dataMeta.endDate : endDateRaw,
        },
        locale: {
          direction: 'ltr',
          format: 'yyyy/mm/dd',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          firstDay: 1,
        },
      }
    },
    async mounted() {
    await this.getAllInvoices()
    this.getAllInvoicesUnsliced()
    this.getBranches()
    },
  methods: {
    async getAllInvoices() {
    await this.emptyArray()
    axios
      .get(process.env.VUE_APP_API_GET_USER, { headers })
      .then(response => {
        response.data.Payload.map(o => {
        const row = []
            row.push('')
            row.push(o.FullName)
            row.push(o.Email)
            row.push(o.PhoneNumber)
            row.push(o.Gender)
            row.push(o.UserRoleName)
            row.push(o.KodeUnitKerja !== null ? o.KodeUnitKerja : '-')
            row.push(o.Kota !== null ? o.Kota : '-')
            row.push(o)
        fetchInvoicesData.push(row)
        })
      })
      .catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching invoice list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      },
    async dateChange(val) {
      this.startDate = val.startDate
      this.endDate = val.endDate
      await this.emptyArray()
      await this.getAllInvoices()
    },
    searchChange({ type, target }) {
      console.log(type)
      console.log('woi', this.searchQuery)
      this.searchQuery = target.value
    },
    dateFormat(value, format = 'MM/DD/YYYY hh:mm') {
        return moment(String(value)).format(format)
    },
    dateSimple(value) {
        let dateRet = ''
        if (value != null) {
          dateRet = moment(String(value)).format('YYYY-MM-DD')
        } else { dateRet = moment().format('YYYY-MM-DD') }
        return dateRet
    },
    getBranches() {
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => {
                  return { label: `${elem.Deskripsi} (${elem.KodeUnitKerja})`, value: elem.KodeUnitKerja }
                })
                this.branches = branchesJoin.sort((a, b) => parseInt(a.value) > parseInt(b.value) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },

    numberFormat(value) {
      return parseFloat(value).toFixed(2)
    },
      async emptyArray() {
        const header = ['No', 'Name', 'Email', 'Phone', 'Gender', 'Role', 'Unit Kerja', 'City', 'Actions']
        fetchInvoicesData.length = 0
        while (fetchInvoicesData.length > 0) {
          fetchInvoicesData.pop()
        }
        fetchInvoicesData.push(header)
      },
      deleteUser(id) {
        const body = {
          UserID: id,
        }
          axios
              .post(process.env.VUE_APP_API_DELETE_USER, body, { headers })
              .then(async response => {
                if (response.data.Status === 1) {
                  await this.getAllInvoices()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Congratulation',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your data has been deleted!',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
                }
              })
              .catch(err => {
                  console.log(err)
              })
      },
      editUser(data) {
          localStorage.setItem('userEdit', data)
          this.$router.push({ name: 'apps-users-edit' })
      },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
