<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @submit.prevent="addNewUser"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
            <b-form-group
              label="Full Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="FullName"
                autofocus
                trim
                placeholder="John Doe"
              />
            </b-form-group>

          <!-- Email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                trim
              />
            </b-form-group>

          <!-- Gender -->
            <b-form-group
              label="Gender"
              label-for="gender"
            >
              <v-select
                v-model="Gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="genders"
                :reduce="val => val.value"
                :clearable="false"
                input-id="gender"
              />
            </b-form-group>

          <!-- Phone Number -->
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="PhoneNumber"
                trim
              />
            </b-form-group>

          <!-- Password -->
            <b-form-group
              label="Password"
              label-for="Password"
            >
              <b-form-input
                id="Password"
                v-model="Password"
                type="password"
                trim
              />
            </b-form-group>

          <!-- Confirm Password -->
            <b-form-group
              label="Confirm Password"
              label-for="ConfirmPassword"
            >
              <b-form-input
                id="ConfirmPassword"
                v-model="ConfirmPassword"
                type="password"
                trim
              />

            </b-form-group>

          <!-- Branch -->
            <b-form-group
              label="Branch"
              label-for="BranchID"
            >
              <v-select
                v-model="BranchID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="branches"
                :reduce="val => val.value"
                :clearable="false"
                input-id="BranchID"
              />
            </b-form-group>

          <!-- User Role -->
            <b-form-group
              label="User Role"
              label-for="RoleID"
            >
              <v-select
                v-model="RoleID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles"
                :reduce="val => val.value"
                :clearable="false"
                input-id="RoleID"
              />
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
                block
                :disabled="invalid"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { getToken } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const Email = ''
const Password = ''
const ConfirmPassword = ''
const FullName = ''
const PhoneNumber = ''
const Gender = ''
const RoleID = ''
const BranchID = ''
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      roles: [],
      branches: [],
      Email,
      Password,
      ConfirmPassword,
      FullName,
      PhoneNumber,
      Gender,
      RoleID,
      BranchID,
    }
  },
  setup() {
    const blankUserData = {
      FullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const genders = [
          { label: 'Male', value: 'L' },
          { label: 'Female', value: 'P' },
        ]
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const onSubmit = () => {
          const userToken = getToken()
          const headers = {
            'Content-Type': 'application/json',
            'X-Token-Access': `Bearer ${userToken}`,
          }
      axios
          .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
          .then(response => {
                const branchesJoin = response.data.Payload.map(elem => (
                { label: `${elem.Deskripsi} (${elem.KodeUnitKerja})`, value: elem.BranchID, kodeunit: elem.KodeUnitKerja }
                ))
                this.branches = branchesJoin.sort((a, b) => parseInt(a.kodeunit) > parseInt(b.kodeunit) ? 1 : -1)
          })
          .catch(err => {
              console.log(err)
          })
    }

    return {
      userData,
      onSubmit,
      genders,
    }
  },
  mounted() {
      this.getRoles()
      this.getBranches()
  },
  methods: {
      getRoles() {
          const userToken = getToken()
          const userRole = this.$cookies.get('UserRole')
          const headers = {
            'Content-Type': 'application/json',
            'X-Token-Access': `Bearer ${userToken}`,
          }
          axios
              .get(process.env.VUE_APP_API_GET_ROLES, { headers })
              .then(response => {
                const rolesJoin = []
                response.data.Payload.map(elem => {
                  if (userRole === 'super-admin') {
                    rolesJoin.push({ label: elem.UserRoleName, value: elem.UserRoleID })
                  } else {
                    if (elem.UserRoleName !== 'Super Admin') {
                    rolesJoin.push({ label: elem.UserRoleName, value: elem.UserRoleID })
                    }
                  }
                })
                this.roles = rolesJoin
              })
              .catch(err => {
                  console.log(err)
              })
      },
      getBranches() {
          const userToken = getToken()
          const headers = {
            'Content-Type': 'application/json',
            'X-Token-Access': `Bearer ${userToken}`,
          }
          axios
              .get(process.env.VUE_APP_API_GET_BRANCH, { headers })
              .then(response => {
                const branchesJoin = response.data.Payload.map(elem => (
                { label: `${elem.Deskripsi} (${elem.KodeUnitKerja})`, value: elem.BranchID, kodeunit: elem.KodeUnitKerja }
                ))
                this.branches = branchesJoin.sort((a, b) => parseInt(a.kodeunit) > parseInt(b.kodeunit) ? 1 : -1)
              })
              .catch(err => {
                  console.log(err)
              })
      },
    addNewUser() {
      const userToken = getToken()
      const headers = {
        'Content-Type': 'application/json',
        'X-Token-Access': `Bearer ${userToken}`,
      }
        const payload = {
          Email: this.email,
          Password: this.Password,
          ConfirmPassword: this.ConfirmPassword,
          FullName: this.FullName,
          PhoneNumber: this.PhoneNumber,
          Gender: this.Gender, // L = Laki-Laki, P = Perempuan
          RoleID: this.RoleID,
          BranchID: this.BranchID,
          }
          console.log(payload)
          axios
        .post(process.env.VUE_APP_API_ADD_USER, payload, { headers })
        .then(response => {
          console.log(response)
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            if (response.data.Status === 1) {
              this.$parent.getAllInvoices()
              this.$parent.isAddNewUserSidebarActive = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Congratulation',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Your data has been inserted!',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: 'Something went wrong',
                },
              })
            }
            }).catch(error => {
              console.log(error)
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: 'Something went wrong',
                    },
                  })
            })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
